// by convention, composable function names start with "use"
import dayjs from 'dayjs'
export const useTracking = () => {
    const trace = () => {
        let t = JSON.parse(localStorage.getItem("t"));
        if(!!t === false) t = []
        t.push({d: dayjs().format('YYYY-MM-DD HH:mm:ss'), r: document.referrer, u: window.location.search})
        localStorage.setItem("t", JSON.stringify(t))
    }

    const clean = () => {
        localStorage.removeItem("t");
    }

    return {trace, clean}
}
